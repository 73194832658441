<!--
man sinfest really sucks, and has for quite a while now...

when did that dude get so anti trans/"woke"/etc?

lame

just keeping this as a vue example
-->
<template>
  <div id="app" class="container mx-auto">
    <h3><a href="https://sinfest.net" target="_blank">Sinfest</a></h3>
    <ul>
      <li v-for="c in comics" class="">
        <h5>{{ c.date }}</h5>
        <img :src="c.url"/>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() { return { comics: [] }; },

  methods: {
    oldComicUrl(d) { return `https://www.sinfest.net/btphp/comics/${d}.gif`; },
    comicUrl(d) { return `https://www.sinfest.xyz/btphp/comics/${d}.jpg`; },

    buildDates() {
      const d = new Date();
      let dates = [];

      for (let i = 30; i > 0; i--) {
        dates = dates.concat(new Date(d - 86400000 * i));
      }

      return dates;
    },

    formatDate(d) {
      return d.toISOString().split('T')[0];
    },

    setComics(dates) {
      this.comics = dates.map((d) => {
        let cu, fd = this.formatDate(d);
        const switchDate = new Date(2021, 4, 23);
        if (d > switchDate) {
            console.log(d, switchDate, "new");
            cu = this.comicUrl(fd);
        } else {
            console.log(d, switchDate, "old");
            cu = this.oldComicUrl(fd);
        }
        return {date: d, url: cu};
      });
    }
  },

  async mounted() {
    this.setComics(this.buildDates());
  },

  name: 'sinfest',

  props: {}
};
</script>

<style>
</style>
