<template>
  <div id="app" class="container mx-auto">
    <div id="welcome">
    </div>
    <!--<span id="sf-link"><router-link to="/comics/sinfest">*</router-link></span>-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      msg: "Soon Orz are really here! You are help Orz with *parties"
    };
  },
  async mounted() {
    const w = document.getElementById('welcome');

    const letters = this.msg.split("");
    for (let i = 0; i < letters.length; i++) {
      let f = (j) => {
        return () => {
          w.append(letters[j]);
        }
      };
      setTimeout(f(i), i * 125);
    }

    // setTimeout(() => {
    //   let l = document.getElementById('sf-link');
    //   l.parentElement.removeChild(l);
    //   w.append(l);
    //   l.style.display = 'inline';
    // }, letters.length * 125);
  },
  name: 'app'
};
</script>

<style>
  html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  #app {
    width: 100%;
    height: 100%;
  }
  #welcome {
    background-image: url('../../img/orz.gif');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: #ff4ff5;
    font-family: arial, helvetica;
    font-size: 8em;
    min-height: 100%;
  }
  #sf-link {
    display: none;
  }
  a {
    color: #ff4ff5;
    text-decoration: none;
  }
  a:hover {
    color: #ff5ffa;
    text-decoration: none;
  }
</style>
