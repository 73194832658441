import './styles.css';
import Vue from 'vue';
import VueRouter from 'vue-router';

import App from './components/App.vue';
import Sinfest from './components/Sinfest.vue';

// Vue.config.productionTip = false;

Vue.use(VueRouter);

const routes = [
  { path: "/", component: App },
  // { path: "/comics/sinfest", component: Sinfest }
];
const router = new VueRouter({routes});
const app = new Vue({router}).$mount('#app');
